// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

export const ROOTS_AUTH = '/auth'
export const ROOTS_DASHBOARD = '/dashboard'
export const ROOTS_USER = '/user'
export const ROOTS_MESSAGE = '/message-center'
export const ROOTS_SUPER_USER_MESSAGE = '/super-user/messages'
export const ROOTS_SERVICES = '/endlets'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  acceptInvite: path(ROOTS_AUTH, '/accept-invite'),
  terms: path(ROOTS_AUTH, '/terms'),
  privacyPolicy: path(ROOTS_AUTH, '/privacy-policy'),
  twoFactorAuth: path(ROOTS_AUTH, '/two-factor-auth'),
  multiFactorAuth: path(ROOTS_AUTH, '/two-factor-auth'),
  backupCodes: path(ROOTS_AUTH, '/backup-codes'),
}

export const PATH_SERVICES = {
  root: ROOTS_SERVICES,
  endlets: {
    endletDetails: (id: any, tab: string) => path(ROOTS_SERVICES, `/${id}/${tab}`),
    tabs: (tab: string) => path(ROOTS_SERVICES, `/${tab}`),
  },
  services: {
    serviceDetails: (id: any) => path(ROOTS_SERVICES, `/services/${id}`),
  },
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}
export const PATH_MESSAGE = { root: ROOTS_MESSAGE }
export const PATH_SUPER_USER_MESSAGE = {
  root: ROOTS_SUPER_USER_MESSAGE,
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  support: {
    ticketDetails: (id: string) =>
      path(ROOTS_DASHBOARD, `/support-center/ticket-details/${id}`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },

  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) =>
      path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-blazer-low-77-vintage/edit'
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt'
    ),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'
    ),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      '/blog/post/apply-these-7-secret-techniques-to-improve-event'
    ),
  },
}

export const PATH_USER = {
  root: ROOTS_USER,
  profile: path(ROOTS_USER, '/profile'),
  admin: path(ROOTS_USER, '/admin'),
  password: path(ROOTS_USER, '/change-password'),
  new: path(ROOTS_DASHBOARD, '/user/new'),
  list: path(ROOTS_DASHBOARD, '/user/list'),
  cards: path(ROOTS_DASHBOARD, '/user/cards'),
  edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
}

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction'
