import React, { useContext, useEffect, useState } from 'react'

//@mui
import {
  Avatar,
  Box,
  Card,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'

//custom
import Scrollbar from 'src/components/Scrollbar'
import Iconify from 'src/components/Iconify'
import { ResellersContext } from 'src/contexts/ResellersContext'
import { get } from 'src/utils/httpMethods'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { useNavigate, useParams } from 'react-router'

//utils
function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function ResellerList() {
  const value = useContext(ResellersContext)!
  const {
    resellers,
    loading,
    currentCompany: selected,
    changeCompany: setSelected,
    reloadResellers,
    setSelectedId,
  } = value!

  const { reseller_id } = useParams()

  const theme = useTheme()
  //   console.log(resellers)

  useEffect(() => {
    setSelectedId(reseller_id)
    console.log(reseller_id)
    reloadResellers('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedId(reseller_id)
  }, [reseller_id, setSelectedId])

  const navigate = useNavigate()

  // state for timer to debounce search
  const [timer, setTimer] = useState<any>(null)

  const inputChanged = (e: any) => {
    const term = e.target.value

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      reloadResellers(term)
    }, 500)

    setTimer(newTimer)
  }

  return (
    <Card
      sx={{
        ml: '30px',
        border: 1,
        borderColor: theme.palette.grey[300],
        position: 'sticky',
        top: 116,
      }}
    >
      <Scrollbar sx={{ height: '80vh' }}>
        <List sx={{ pb: 0 }}>
          <Box sx={{ px: 3, mt: 3, mb: 2 }} key="searchField">
            <TextField
              fullWidth
              sx={{ bgcolor: theme.palette.background.paper, borderRadius: 1 }}
              // value={filterName}
              // onChange={(event) => onFilterName(event.target.value)}
              placeholder="Search Reseller..."
              onChange={inputChanged}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={'eva:search-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Divider />
          {!resellers.length ? (
            !loading ? (
              <>
                <Typography sx={{ pl: 2, color: 'text.secondary' }} variant="h5">
                  No Reseller found
                </Typography>
              </>
            ) : (
              <>
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
                <Skeleton animation="wave" height={60} />
              </>
            )
          ) : (
            <>
              {resellers.map((reseller: any) => (
                <React.Fragment key={reseller?.id}>
                  <ListItem
                    alignItems="center"
                    selected={selected?.id === reseller?.id}
                    onClick={() => {
                      console.log(reseller?.id)
                      navigate(`/super-user/reseller/${reseller?.id}`)
                      setSelected(reseller)
                    }}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: theme.palette.background.paper,
                      '&:hover': {
                        bgcolor: theme.palette.background.neutral,
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          bgcolor: stringToColor(reseller?.name),
                          color: 'white',
                          height: 32,
                          width: 32,
                        }}
                      >
                        <Typography variant="h5">
                          {reseller.name.charAt(0)}
                        </Typography>
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary={reseller.name} />
                  </ListItem>
                  <Divider sx={{ bgcolor: 'text.primary' }} />
                </React.Fragment>
              ))}
            </>
          )}
        </List>
      </Scrollbar>
    </Card>
  )
}

export default ResellerList
