import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import BusinessIcon from '@mui/icons-material/Business'
import { Box, Button } from '@mui/material'
import SelectTenantModal from './SelectTenantModal'
import { get } from 'src/utils/httpMethods'
import useAuth from 'src/hooks/useAuth'
import useSettings from 'src/hooks/useSettings'
function UserDropdown() {
  const { user } = useAuth()
  const [openSelectTenant, setOpenSelectTenant] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState<any>()
  const [tenantsList, setTenantsList] = useState<any>([])

  const fetchTenants = async () => {
    const SUB_URL =
      user?.role === 'Reseller_Admin' ? 'resellers/customers' : 'customers'
    try {
      const res = await get(`${process.env.REACT_APP_HOST_API_URL}/${SUB_URL}`)
      setTenantsList(res)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchTenants()
  }, [])

  useEffect(() => {
    setSelectedTenant(
      tenantsList.length &&
        tenantsList.find(
          (ten: any) => ten.id === localStorage.getItem('selecetedTenant')
        )
    )
  }, [tenantsList])

  const { themeLayout } = useSettings()
  console.log(themeLayout)

  return (
    <div>
      <SelectTenantModal
        openProps={{ open: openSelectTenant, setOpen: setOpenSelectTenant }}
        selectTenant={{ selectedTenant, setSelectedTenant }}
        list={tenantsList}
        setList={setTenantsList}
      />

      <Box
        component="span"
        sx={{
          color: 'text.secondary',
        }}
        className="d-flex align-items-center"
      >
        <Button
          onClick={() => setOpenSelectTenant(true)}
          sx={{
            color: themeLayout === 'vertical' ? 'text.disabled' : 'text.secondary',
          }}
        >
          <BusinessIcon />
          {selectedTenant ? (
            <>
              <span className="ms-2">{selectedTenant.name}</span>
              <ExpandMoreIcon />
            </>
          ) : (
            <>
              <span className="ms-2">Select tenant</span>
              <ExpandMoreIcon />
            </>
          )}
        </Button>
      </Box>
    </div>
  )
}

export default UserDropdown
