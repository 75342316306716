import React, { createContext, ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Reseller } from 'src/pages/super-user/ResellerManagement'
import { get } from 'src/utils/httpMethods'

interface ResellerContextType {
  loading: boolean
  resellers: Reseller[]
  currentCompany: Reseller
  changeCompany: React.Dispatch<React.SetStateAction<Reseller>>
  reloadResellers: React.Dispatch<React.SetStateAction<string>>
  setSelectedId: React.Dispatch<React.SetStateAction<any>>
}

export const ResellersContext = createContext<ResellerContextType | null>(null)

function ResellersProvider({ children }: { children: ReactNode }) {
  const [resellerList, setResellerList] = useState([] as any)
  const [selectedCompany, setSelectedCompany] = useState({} as any)

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const noteList = useRef([] as any)

  const [isResellerPage, setIsResellerPage] = useState(false)

  const { pathname } = useLocation()
  const [selectedId, setSelectedId] = useState<any>(
    pathname.split('/')[pathname.split('/').length - 1]
  )

  useEffect(() => {
    const isreseller = pathname.split('/').includes('reseller')
    setIsResellerPage(isreseller)
  }, [pathname])

  useEffect(() => {
    resellerList.length !== 0 &&
      selectedId?.length &&
      setSelectedCompany(resellerList?.find((elem: any) => elem.id === selectedId))
  }, [resellerList, selectedId])

  ////////////////////////////////////////
  //   for Reseller dropdown local value
  ////////////////////////////////////////

  const fetchResellers = (term = '') => {
    console.log('----------------------')
    if (!isResellerPage) return
    setLoading(true)
    get(`/resellers?term=${term}`)
      .then((response: any) => {
        console.log(selectedId)
        setResellerList(response)
        navigate(`/super-user/reseller/${selectedId ? selectedId : response[0].id}`)
        // if (!selectedId.length) {
        //   if (response?.length) {
        //     setSelectedCompany(response[0])
        //     if (response[0]?.id) {
        //       fetchNotes(response[0])
        //     }
        //   }
        // } else {
        //   setSelectedCompany(response.find((elem: any) => elem.id === selectedId))
        // }

        setLoading(false)
      })
      .catch((error: any) => {})
  }

  const fetchNotes = (company: Reseller) => {
    get(`/customers/${company.id}/notes`)
      .then((response) => {
        noteList.current = response
        setSelectedCompany({
          ...company,
          notes: response || [],
        })
      })
      .catch((error: any) => {})
  }
  useEffect(() => {
    fetchResellers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResellerPage])
  return (
    <ResellersContext.Provider
      value={{
        setSelectedId,
        loading: loading,
        resellers: resellerList,
        currentCompany: selectedCompany,
        changeCompany: (selectedCustomer: any) => {
          setSelectedCompany(selectedCustomer)
          if (selectedCustomer?.id) {
            fetchNotes(selectedCustomer)
          }
        },
        reloadResellers: (term) => {
          fetchResellers(term as any)
        },
      }}
    >
      {children}
    </ResellersContext.Provider>
  )
}

export default ResellersProvider
