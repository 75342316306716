import React, { createContext, ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import useAuth from 'src/hooks/useAuth'
import { Customer } from 'src/pages/super-user/ManageCustomers'
import { get } from 'src/utils/httpMethods'

interface CustomerContextType {
  loading: boolean
  customers: Customer[]
  currentCompany: Customer
  changeCompany: React.Dispatch<React.SetStateAction<Customer>>
  reloadCustomers: React.Dispatch<React.SetStateAction<string>>
  setSelectedTenant: React.Dispatch<React.SetStateAction<string>>
  setSelectedId: React.Dispatch<React.SetStateAction<any>>
}

export const CustomersContext = createContext<CustomerContextType | null>(null)

function CustomersProvider({ children }: { children: ReactNode }) {
  const [customerList, setCustomerList] = useState([] as any)
  const [selectedCompany, setSelectedCompany] = useState({} as any)

  const [loading, setLoading] = useState(false)
  const noteList = useRef([] as any)
  const { user } = useAuth()
  const [isCustomerPage, setIsCustomerPage] = useState(false)

  const { pathname } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const iscustomer = pathname.split('/').includes('customers')
    setIsCustomerPage(iscustomer)
  }, [pathname])

  const [selectedId, setSelectedId] = useState<any>()

  ////////////////////////////////////////
  //   for customer dropdown local value
  ////////////////////////////////////////

  const [selectedTenant, setSelectedTenant] = useState(
    localStorage.getItem('selecetedTenant') || ''
  )

  useEffect(() => {
    customerList.length !== 0 &&
      selectedId?.length &&
      setSelectedCompany(customerList?.find((elem: any) => elem.id === selectedId))
  }, [customerList, selectedId])

  console.log(selectedId)

  useEffect(() => {
    localStorage.setItem('selecetedTenant', selectedTenant)
  }, [selectedTenant])

  const fetchCustomers = (term = '') => {
    if (!isCustomerPage) return
    setLoading(true)
    const URL =
      user?.role === 'Reseller_Admin' ? '/resellers/customers' : '/customers'
    get(`${URL}?term=${term}`)
      .then((response: any) => {
        setCustomerList(response)
        setLoading(false)
        navigate(`/super-user/customers/${selectedId ? selectedId : response[0].id}`)
        if (!selectedId) {
          setSelectedCompany(response[0])
        }
      })
      .catch((error: any) => {})
  }

  const fetchNotes = (company: Customer) => {
    const URL =
      user?.role === 'Reseller_Admin' ? '/resellers/customers' : '/customers'
    get(`${URL}/${company.id}/notes`)
      .then((response) => {
        noteList.current = response
        setSelectedCompany({
          ...company,
          notes: response || [],
        })
      })
      .catch((error: any) => {})
  }

  useEffect(() => {
    fetchCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerPage])

  return (
    <CustomersContext.Provider
      value={{
        setSelectedTenant,
        loading: loading,
        customers: customerList,
        currentCompany: selectedCompany,
        changeCompany: (selectedCustomer: any) => {
          setSelectedCompany(selectedCustomer)
          if (selectedCustomer?.id) {
            fetchNotes(selectedCustomer)
          }
        },
        reloadCustomers: (term) => {
          fetchCustomers(term as any)
        },
        setSelectedId,
      }}
    >
      {children}
    </CustomersContext.Provider>
  )
}

export default CustomersProvider
