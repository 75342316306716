import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormProvider, RHFTextField } from '../../../components/hook-form'

import { useNavigate } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'
import useReacaptcha from 'src/hooks/useReacaptcha'
import { Box, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
type Inputs = {
  otp: string
}

function VerifyOTP() {
  const loginSchema = yup.object().shape({
    otp: yup.string().required('Please enter code'),
  })

  const defaultValues = {
    otp: '',
  }
  const methods = useForm<Inputs>({
    resolver: yupResolver(loginSchema),
    defaultValues,
  })

  const [useBackupCode, setUseBackupCode] = useState(false)

  const {
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
    // formState: { errors },
  } = methods
  const { otp } = watch()
  console.log(otp)
  const navigate = useNavigate()
  const { isProcessing, verifyOTP, MFA } = useAuth()

  const { recaptchaVerify } = useReacaptcha('login')

  const onSubmit = async (data: Inputs) => {
    console.log('first')
    const token = await recaptchaVerify()
    console.log(token)
    if (token.length) {
      try {
        await verifyOTP(data.otp, token, useBackupCode)
      } catch (e: any) {
        setError('otp', { type: 'custom', message: e.message })
      }
    }
  }

  useEffect(() => {
    if (!MFA || !MFA.mfaHash) {
      navigate('/auth/login')
    }
  }, [MFA])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0%',
        justifyContent: 'center',
      }}
      //   className="flex h-full-vh flex-1 flex-col justify-center sm:px-6 lg:px-8"
    >
      <Box>
        {/* <WhiteLabelLogo /> */}
        <h2
          style={{
            marginTop: '16px',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '-0.025em',
            textAlign: 'center',
            color: '#111827',
          }}
          //   className="mt-4 text-center text-xl leading-9 tracking-tight text-gray-900"
        >
          Two-Factor authentication
        </h2>
        <Box
          sx={{
            marginTop: '8px',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#6B7280',
          }}
          //   className="mt-2 text-gray-500 text-sm text-center"
        >
          {useBackupCode
            ? 'Enter the 6-digit backup code saved by you to confirm your action.'
            : 'Enter the 6-digit code generated by your app to confirm your action.'}{' '}
          (Do not refresh this page)
        </Box>
      </Box>

      <Box
        sx={
          {
            // marginTop: '10px',
          }
        }
        // className="mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]"
      >
        <Box
          sx={{
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '48px',
            paddingBottom: '48px',
            backgroundColor: '#ffffff',
            boxShadow:
              '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          }}
          //   className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12"
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack
              sx={
                {
                  // display: 'flex',
                  // justifyContent: 'center',
                  // alignItems: 'center',
                }
              }
            >
              {/* <RHFTextField
                sx={{ width: '49%' }}
                name="firstName"
                label="First Name"
              /> */}
              <Box
                sx={{
                  display: 'flex',
                  width: '49%',
                  flexDirection: 'column',
                  mx: 'auto',
                  mb: '15px',
                }}
              >
                <TextField
                  // onChange={(e) => setOTP(e.target.value)}
                  placeholder={
                    useBackupCode ? `Enter backup code` : `Enter one time password`
                  }
                  name="otp"
                  // label="Outlined"
                  variant="outlined"
                  onChange={(e) => {
                    setError('otp', {})
                    setValue('otp', e.target.value)
                  }}
                />
                {errors.otp && (
                  <Typography
                    variant="caption"
                    color={'error'}
                    sx={{ mt: '5px', mx: '0' }}
                  >
                    {errors.otp?.message}
                  </Typography>
                )}
              </Box>
              <LoadingButton
                // fullWidth
                // onClick={handlesubmit}
                sx={{ width: '49%', mx: 'auto' }}
                variant="contained"
                type="submit"
                loading={isProcessing}
              >
                Verify
              </LoadingButton>
            </Stack>
          </FormProvider>

          <Box sx={{ mt: '20px' }}>
            <Typography
              sx={{
                marginTop: '40px',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                color: '#6B7280',
              }}
              // className="mt-10 text-center text-sm text-gray-500"
            >
              {useBackupCode ? `Found your device?` : `Can't find your device?`}{' '}
              <a
                href="#!"
                onClick={() => setUseBackupCode(!useBackupCode)}
                style={{ fontWeight: 600, lineHeight: '1.5rem', color: 'red' }}
                // className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                {useBackupCode ? 'Use OTP' : 'Use backup code.'}
              </a>
            </Typography>
            <Typography
              sx={{
                marginTop: '8px',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                color: '#6B7280',
              }}
              // className="mt-2 text-center text-sm text-gray-500"
            >
              Not sure about anything?{' '}
              <a
                href="/auth/login"
                style={{ fontWeight: 600, lineHeight: '1.5rem', color: 'red' }}
                // className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Return to login
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default VerifyOTP
