import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

//mui
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

//hooks
import { roles } from 'src/_mock'
import { RHFTextField, FormProvider, RHFSelect } from 'src/components/hook-form'
import { useForm } from 'react-hook-form'

import { Box, Stack } from '@mui/material'
import { post } from 'src/utils/httpMethods'
import { LoadingButton } from '@mui/lab'
import { OpenModalProps, SnackbarProps } from 'src/@types/modalProps'
import { Alert, Snackbar } from '@mui/material'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { ResellersContext } from 'src/contexts/ResellersContext'

type FormValuesProps = {
  firstName: string
  lastName: string
  companyName: string
  subdomain: string
  email: string
}

interface Props {
  openProps: OpenModalProps
}

function InviteResellerModal({ openProps }: Props) {
  const { reloadResellers } = useContext(ResellersContext)!
  const { snackbarProps, setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const { open, setOpen } = openProps

  const handleClose = () => {
    setOpen(false)
    reset()
  }

  const defaultValues = {
    firstName: '',
    lastName: '',
    companyName: '',
    subdomain: '',
    email: '',
  }
  const [isError, setIsError] = useState({
    errorFlag: false,
    errorMsg: '',
  })

  const UpdateResellerSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    companyName: Yup.string(),
    subdomain: Yup.string(),
    email: Yup.string().email().required('Email is required'),
  })
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateResellerSchema),
    defaultValues,
  })

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await post(`${process.env.REACT_APP_HOST_API_URL}/resellers/invite`, {
        ...data,
        subdomain: data.subdomain + '21packets.stagebuilds.com',
      }).then((response: any) => {
        handleClose()
        setSnackbarProps({
          open: true,
          message: 'Reseller invited successfully!',
          severity: 'success',
        })
        reloadResellers('')
      })

      handleClose()
    } catch (error) {
      setSnackbarProps({
        open: true,
        message: error.message,
        severity: 'error',
      })
    }
  }

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods
  const handleCloseSnackbar = () => {
    setIsError({ ...isError, errorFlag: false })
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} scroll="body">
        <DialogTitle>Invite Reseller</DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Enter email address to invite Reseller
            </DialogContentText>
            <Box
              sx={{
                mt: '20px',
                width: 500,
              }}
            >
              {/* <RHFTextField name="userName" label="User Name" /> */}
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ mb: 2 }}
                spacing={2}
              >
                <RHFTextField name="firstName" label="First Name" />
                <RHFTextField name="lastName" label="Last Name" />
              </Stack>
              <RHFTextField name="companyName" label="Company Name" sx={{ mb: 2 }} />
              <RHFTextField name="subdomain" label="Subdomain" sx={{ mb: 2 }} />
              <RHFTextField name="email" label="Email Address" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="text"
              sx={{ color: 'text.secondary' }}
              type="button"
            >
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Invite
            </LoadingButton>
          </DialogActions>
        </FormProvider>
        <Snackbar
          open={isError.errorFlag}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={() => {
              setIsError({ ...isError, errorFlag: false })
            }}
            severity="error"
          >
            {isError.errorMsg}
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  )
}

export default InviteResellerModal
