import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

// @mui
import { styled } from '@mui/material/styles'
import { Card, Container, Link, Typography } from '@mui/material'

// custom
import { PATH_AUTH } from '../../routes/paths'
import Page from 'src/components/Page'
import LogoBlackText from 'src/components/LogoBlackText'
import useResponsive from 'src/hooks/useResponsive'
import AddQRCard from 'src/sections/auth/verify-code/AddQRCard'
import VerifyOTP from 'src/sections/auth/verify-code/VerifyOTP'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  // zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

function AddQRtoAccount() {
  const smUp = useResponsive('up', 'sm')

  return (
    <Page title="Scan QR" sx={{ bgcolor: '#F9FAFB ' }}>
      <RootStyle>
        <HeaderStyle>
          <LogoBlackText />
          {smUp && (
            <Typography variant="body2" fontSize="18px" sx={{ mt: { md: -2 } }}>
              Already have an account? {''}
              <Link
                variant="subtitle2"
                fontSize="18px"
                component={RouterLink}
                to={PATH_AUTH.login}
              >
                Login
              </Link>
            </Typography>
          )}
        </HeaderStyle>
        <Container maxWidth="md">
          <ContentStyle>
            <Card elevation={6}>
              {/* <AddQRCard /> */}
              <VerifyOTP />
            </Card>

            {!smUp && (
              <Typography
                variant="body2"
                fontSize="18px"
                align="center"
                sx={{ mt: 3 }}
              >
                Don’t have an account?
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                  fontSize="18px"
                >
                  Get started
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}

export default AddQRtoAccount
