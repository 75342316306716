import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
  useRef,
  useContext,
} from 'react'

// react-firebase
import { useAuthState } from 'react-firebase-hooks/auth'

// utils
import { isValidToken, setRefreshToken, setSession } from '../utils/jwt'
// @types
import {
  ActionMap,
  AuthOrganization,
  AuthState,
  AuthUser,
  JWTContextType,
} from '../@types/auth'

// SAML & Auth imports
import {
  getAuth,
  SAMLAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  multiFactor,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  linkWithPopup,
  deleteUser,
  sendEmailVerification,
  reauthenticateWithCredential,
} from 'firebase/auth'

import { useNavigate, useLocation } from 'react-router-dom'
import { ROOTS_AUTH } from 'src/routes/paths'
import { get, post, put } from 'src/utils/httpMethods'
import { messages } from 'src/utils/errorMessages'
import { ToplevelSnackbarContext } from './SnackbarContext'
import { isBaseLocation } from 'src/utils/checkBaseLoc'
import { TopLevelmodalContext } from './ModalContext'
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Refetch = 'REFETCH',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean
    user: AuthUser
    organization: AuthOrganization
  }
  [Types.Login]: {
    user: AuthUser
    organization: AuthOrganization
  }
  [Types.Logout]: undefined
  [Types.Register]: {
    user: AuthUser
    organization: AuthOrganization
  }
  [Types.Refetch]: {
    user: AuthUser
    organization: AuthOrganization
  }
}

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>]

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  organization: null,
}

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        organization: action.payload.organization,
      }
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        organization: action.payload.organization,
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        organization: null,
      }

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        organization: action.payload.organization,
      }
    case 'REFETCH':
      return {
        ...state,
        isInitialized: true,
        user: action.payload.user,
        organization: action.payload.organization,
      }
    default:
      return state
  }
}

const AuthContext = createContext<JWTContextType | null>(null)

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode
}

declare global {
  interface Window {
    MyNamespace: any
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const {
    alertMFAModalProps: { show },
    setAlertMFAModalProps,
  } = useContext(TopLevelmodalContext)!
  const [state, dispatch] = useReducer(JWTReducer, initialState)
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [multiFactorResolver, setMultiFactorResolver] = useState<any>({})
  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isEnrolled, setIsEnrolled] = useState(false)
  const [user, setUser] = useState<any>(null)
  const [MFA, setMFADetails] = useState({} as any)
  const [organization, setOrganization] = useState(null)
  const [failedAttempts, setFailedAttempts] = useState({
    verified: false,
    message: '',
    failedCount: 0,
  })
  const [mfaStatus, setMfaStatus] = useState({
    authenticatorApp: '',
    enabled: false,
    id: '',
    phone: {
      phoneNumber: '',
      countryCode: '',
    },
  })

  /* context variable for changing mfa details */
  const [changeMfa, setChangeMfa] = useState({
    authenticatorApp: '',
    qrURL: '',
    secret: '',
  })
  const recaptchaRef = useRef<any>(null)

  const [unreadMessages, setUnreadMessages] = useState('')

  const [autoLogout, setAutoLogout] = useState<any>({})

  useEffect(() => {
    const countdown = parseInt(state.user?.MFA.mfaDisabledAt) + 86400000 - Date.now()
    // const countdown = 10000

    console.log(parseInt(state.user?.MFA.mfaDisabledAt) + 86400000 - Date.now())
    console.log(countdown)
    setAutoLogout((prev: any) => ({ ...prev, counter: countdown }))

    let seconds = Math.floor(countdown / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60
    hours = hours % 24
    const style =
      'background-color: darkblue ; color: white; font-style: italic; border: 5px solid  hotpink ; font-size: 1.5em; padding:6px;'
    console.log(`%c${hours}h:${minutes}m:${seconds}s Before logout`, style)
  }, [state.user])

  useEffect(() => {
    let to: any
    if (!isNaN(autoLogout.counter)) {
      if (Math.sign(autoLogout.counter) !== -1) {
        console.log('timeout started')
        to = setTimeout(() => {
          logout()
        }, autoLogout.counter)
      }
    }

    return () => {
      clearTimeout(to)
    }
  }, [autoLogout.counter])

  useEffect(() => {
    if (!location.pathname.includes('auth')) {
      const initialize = async () => {
        try {
          const accessToken = localStorage.getItem('accessToken')
          // handleRefreshToken(refreshToken)

          // Validate access token
          console.log('^^^^ Token Found, verifying if expired ^^^^')
          const validToken = await isValidToken(accessToken ? accessToken : '')

          if (validToken) {
            setSession(accessToken)
            console.log('valid token found')

            const additionalUserData: any = await fetchAdditionalUserData()
            console.log('tokenfound setting session')
            saveProviderId(additionalUserData?.organization)

            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user: { ...additionalUserData.user },
                organization: additionalUserData?.organization,
              },
            })

            console.log('tokenfound setting session')

            // Get unread messages count
            getUnreadMessagesCount()

            location.pathname.includes(ROOTS_AUTH) && navigate('/dashboard')
          } else {
            // Locally stored token is NOT VALID
            // Dispatch null and redirect to login
            await logout()
          }
        } catch (err) {
          await logout()
        }
      }
      initialize()
    } else {
      setMfaStatus((prev) =>
        JSON.parse(localStorage.getItem('mfaStatus') ?? JSON.stringify({ ...prev }))
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
   * Send verification email
   */
  // const sendVerificationEmail = async (token: string) => {
  //   await get(`/auth/self/verification-email`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   })
  // }

  // const login = async (email: string, password: string) => {
  //   try {
  //     const tempSignIn: any = await post(
  //       `${process.env.REACT_APP_HOST_API_URL}/auth/signin`,
  //       {
  //         email: email,
  //         password: password,
  //       }
  //     )

  //     setIsLoggingIn(false)
  //     localStorage.setItem('accessToken', tempSignIn.token)
  //     navigate(
  //       tempSignIn.MFA.enabled ? '/auth/scan-code' : '/auth/multi-factor-auth'
  //     )
  //   } catch (e) {
  //     setIsLoggingIn(false)

  //     console.log(e)
  //   }
  // }

  /* sign in and get temporary token for MFA verification */
  const signIn = async (email: string, password: string, recaptchaToken: string) => {
    setIsLoggingIn(true)

    try {
      const loginRes: any = await post(
        `${process.env.REACT_APP_HOST_API_URL}/auth/signin`,
        {
          email: email,
          password: password,
          recaptchaToken: recaptchaToken,
        }
      )

      setIsLoggingIn(false)
      // localStorage.setItem(
      //   'tempPhone',
      //   (loginRes.MFA.phone.countryCode + loginRes.MFA.phone.phoneNumber).replace(
      //     /.(?=.{4,}$)/g,
      //     '*'
      //   )
      // )
      // localStorage.setItem('accessToken', loginRes.token)
      // setMfaStatus({
      //   ...loginRes.MFA,
      //   id: loginRes.id,
      // })
      // localStorage.setItem(
      //   'mfaStatus',
      //   JSON.stringify({
      //     ...loginRes.MFA,
      //     id: loginRes.id,
      //   })
      // )
      // setFailedAttempts((prev) => ({
      //   ...prev,
      //   verified: loginRes.MFA?.enabled,
      //   failedCount: 0,
      // }))

      // navigate(loginRes.MFA?.enabled ? '/auth/scan-code' : '/auth/multi-factor-auth')

      const { isMfaEnabled, mfaHash } = loginRes

      // Navigate to verify OTP screen if MFA is enabled
      if (isMfaEnabled) {
        setMFADetails({ password, mfaHash, email })
        localStorage.setItem(
          'tempPhone',
          (loginRes.phone.countryCode + loginRes.phone.phoneNumber).replace(
            /.(?=.{4,}$)/g,
            '*'
          )
        )
        // setSnackbarProps({
        //   open: true,
        //   message: `${loginRes.message}`,
        //   severity: 'success',
        // })
        setIsLoggingIn(false)
        navigate('/auth/scan-code')
        return loginRes
      }

      const {
        tokens: {
          access: { token },
          refresh,
        },
        user,
      } = loginRes
      setSession(token)
      const additionalUserData: any = await fetchAdditionalUserData()
      dispatch({
        type: Types.Login,
        payload: {
          user: {
            ...additionalUserData.user,
            accessToken: token,
            user,
            refreshToken: refresh.token,
          },
          organization: additionalUserData?.organization,
        },
      })

      setRefreshToken(refresh.token)
      saveProviderId(additionalUserData?.organization)
      // setUser(user)
      // setOrganization(additionalUserData?.organization)
      // Get unread messages count
      getUnreadMessagesCount()
      navigate(`/dashboard`)
      setAlertMFAModalProps({
        show:
          (user.MFA.showEnableMfaAdvise ||
            user?.MFA?.showEnableMfaAdvise === undefined) &&
          (!user.MFA.enabled || user?.MFA?.enabled === undefined),
      })
      setIsProcessing(false)

      // return loginRes
    } catch (e) {
      console.log(e)
      setIsLoggingIn(false)
      throw e
    }
  }

  /* generate a QR code for the user */
  const generateQR = async () => {
    const res: any = await get(`/auth/qr`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
    return res
  }

  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  /* verify the otp entered by user */
  const verifyOnly = async (otp: string, recaptchaToken: string) => {
    try {
      const loginRes: any = await post(
        `/auth/otp-only`,
        {
          userToken: otp,
          authenticatorApp: mfaStatus.authenticatorApp,
          recaptchaToken: recaptchaToken,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      )

      const { verified } = loginRes
    } catch (error) {
      setIsLoggingIn(false)
      setFailedAttempts((prev) => ({ ...prev, failedCount: error.failedCount }))
      setSnackbarProps({
        open: true,
        message: `${error.message}`,
        severity: 'error',
      })
      throw new Error(messages.error[error.code] || error.message)
    }
  }

  const verifyTOTP = async (verified: boolean) => {
    try {
      const loginRes: any = await post(
        `/auth/otp`,
        {
          verified: verified,
          authenticatorApp: mfaStatus.authenticatorApp,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      )
      const {
        tokenData: { idToken, refreshToken },
        userData: { users },
        backupCodes,
      } = loginRes
      console.log('setsession 3')
      setSession(idToken)
      console.log('login res====>', loginRes)
      const additionalUserData: any = await fetchAdditionalUserData()

      dispatch({
        type: Types.Login,
        payload: {
          user: {
            ...additionalUserData.user,
            accessToken: idToken,
            ...users[0],
            refreshToken: refreshToken,
          },
          organization: additionalUserData?.organization,
        },
      })

      setRefreshToken(refreshToken)
      localStorage.setItem('backupCodes', JSON.stringify(backupCodes) ?? '')
      saveProviderId(additionalUserData?.organization)

      // Get unread messages count
      getUnreadMessagesCount()

      navigate(backupCodes ? `/auth/backup-codes` : `/dashboard`, {
        replace: true,
      })
    } catch (error) {
      setIsLoggingIn(false)
      setFailedAttempts((prev) => ({ ...prev, failedCount: error.failedCount }))
      setSnackbarProps({
        open: true,
        message: `${error.message}`,
        severity: 'error',
      })
      throw new Error(messages.error[error.code] || error.message)
    }
  }

  const verifyBackupCode = async (backupCode: string) => {
    try {
      const bkpRes: any = await post(
        `auth/backup-code`,
        {
          code: backupCode,
        },
        {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem('accessToken')?.toString()}` ?? '',
          },
        }
      )

      const {
        tokenData: { idToken, refreshToken },
        userData: { users },
      } = bkpRes
      setSession(idToken)
      console.log('login res====>', bkpRes)
      const additionalUserData: any = await fetchAdditionalUserData()

      if (!additionalUserData?.user?.emailVerified) {
        await logout()
        // await sendVerificationEmail(accessToken)
        throw new Error(messages.error.userNotActivated)
      }

      dispatch({
        type: Types.Login,
        payload: {
          user: {
            ...additionalUserData.user,
            accessToken: idToken,
            ...users[0],
            refreshToken: refreshToken,
          },
          organization: additionalUserData?.organization,
        },
      })

      setRefreshToken(refreshToken)
      saveProviderId(additionalUserData?.organization)

      // Get unread messages count
      getUnreadMessagesCount()

      navigate(`/dashboard`, { replace: true })
    } catch (error) {
      setIsLoggingIn(false)
      setFailedAttempts((prev) => ({ ...prev, failedCount: error.failedCount }))
      setSnackbarProps({
        open: true,
        message: `${error.message}`,
        severity: 'error',
      })
      throw new Error(messages.error[error.code] || error.message)
    }
  }

  /*
   * Verify SMS OTP
   */
  const verifySmsOTP = async (otp: string) => {
    try {
      const bkpRes: any = await post(
        `auth/sms-otp`,
        {
          otp: otp,
        },
        {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem('accessToken')?.toString()}` ?? '',
          },
        }
      )

      const {
        tokenData: { idToken, refreshToken },
        userData: { users },
      } = bkpRes
      setSession(idToken)
      console.log('login res====>', bkpRes)
      const additionalUserData: any = await fetchAdditionalUserData()

      if (!additionalUserData?.user?.emailVerified) {
        await logout()
        // await sendVerificationEmail(accessToken)
        throw new Error(messages.error.userNotActivated)
      }

      dispatch({
        type: Types.Login,
        payload: {
          user: {
            ...additionalUserData.user,
            accessToken: idToken,
            ...users[0],
            refreshToken: refreshToken,
          },
          organization: additionalUserData?.organization,
        },
      })

      setRefreshToken(refreshToken)
      saveProviderId(additionalUserData?.organization)

      // Get unread messages count
      getUnreadMessagesCount()

      navigate(`/dashboard`, { replace: true })
    } catch (error) {
      setIsLoggingIn(false)
      setFailedAttempts((prev) => ({ ...prev, failedCount: error.failedCount }))
      setSnackbarProps({
        open: true,
        message: `${error.message}`,
        severity: 'error',
      })
      throw new Error(messages.error[error.code] || error.message)
    }
  }

  const [duoVerified, setDuoVerified] = useState(false)

  /*
   * verify user created using Duo
   */
  const verifyDuoUser = async (factor: string, username: string) => {
    const finalData = { factor, username }

    try {
      const verifyDuoRes: any = await post(
        `/auth-duo-user`,
        { ...finalData },
        {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem('accessToken')?.toString()}` ?? '',
          },
        }
      )
      setDuoVerified(true)
      const {
        tokenData: { idToken, refreshToken },
        userData: { users },
        backupCodes,
      } = verifyDuoRes
      console.log('setsession 3')
      setSession(idToken)
      console.log('login res====>', verifyDuoRes)
      const additionalUserData: any = await fetchAdditionalUserData()

      dispatch({
        type: Types.Login,
        payload: {
          user: {
            ...additionalUserData.user,
            accessToken: idToken,
            ...users[0],
            refreshToken: refreshToken,
          },
          organization: additionalUserData?.organization,
        },
      })

      setRefreshToken(refreshToken)
      localStorage.setItem('backupCodes', JSON.stringify(backupCodes) ?? '')
      saveProviderId(additionalUserData?.organization)
      navigate(backupCodes ? `/auth/backup-codes` : `/dashboard`, {
        replace: true,
      })
      setDuoVerified(false)
      console.log(verifyDuoRes)
    } catch (error) {
      setIsLoggingIn(false)
      setFailedAttempts((prev) => ({ ...prev, failedCount: error.failedCount }))
      setSnackbarProps({
        open: true,
        message: `${error.message}`,
        severity: 'error',
      })
      throw new Error(messages.error[error.code] || error.message)
    }
  }

  /*
   * Verify duo using passcode
   */
  const verifyDuoPasscode = async (
    factor: string,
    username: string,
    passcode: string
  ) => {
    const finalData = { factor, passcode, username }

    try {
      const verifyDuoRes: any = await post(
        `/auth-duo-user`,
        { ...finalData },
        {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem('accessToken')?.toString()}` ?? '',
          },
        }
      )

      const {
        tokenData: { idToken, refreshToken },
        userData: { users },
        backupCodes,
      } = verifyDuoRes
      console.log('setsession 3')
      setSession(idToken)
      console.log('login res====>', verifyDuoRes)
      const additionalUserData: any = await fetchAdditionalUserData()

      dispatch({
        type: Types.Login,
        payload: {
          user: {
            ...additionalUserData.user,
            accessToken: idToken,
            ...users[0],
            refreshToken: refreshToken,
          },
          organization: additionalUserData?.organization,
        },
      })

      setRefreshToken(refreshToken)
      localStorage.setItem('backupCodes', JSON.stringify(backupCodes) ?? '')
      saveProviderId(additionalUserData?.organization)
      navigate(backupCodes ? `/auth/backup-codes` : `/dashboard`, {
        replace: true,
      })

      console.log(verifyDuoRes)
    } catch (error) {
      setIsLoggingIn(false)
      setFailedAttempts((prev) => ({ ...prev, failedCount: error.failedCount }))
      setSnackbarProps({
        open: true,
        message: `${error.message}`,
        severity: 'error',
      })
      throw new Error(messages.error[error.code] || error.message)
    }
  }

  /*
   * Login user using Firebase lib methods
   */
  const login = async (email: string, password: string) => {
    setIsLoggingIn(true)
    const auth = await getAuth()
    if (!auth.currentUser?.emailVerified)
      await signInWithEmailAndPassword(auth, email, password)
        .then(async (response: any) => {
          const { accessToken } = response.user
          const { user } = response
          console.log(user)
          setSession(accessToken)
          const additionalUserData: any = await fetchAdditionalUserData()

          // DON'T Allow user to sign in if email is not verified
          if (!additionalUserData?.user?.emailVerified) {
            await logout()
            // await sendVerificationEmail(accessToken)
            throw new Error(messages.error.userNotActivated)
          }

          dispatch({
            type: Types.Login,
            payload: {
              user: { ...user, ...additionalUserData.user },
              organization: additionalUserData?.organization,
            },
          })

          saveProviderId(additionalUserData?.organization)
          setIsLoggingIn(false)
          navigate('/dashboard')

          // navigate(
          //   !additionalUserData.user.MFA
          //     ? `/auth/scan-code`
          //     : `/auth/verify-authenticator`
          // )
        })
        .catch((error) => {
          if (error.code === 'auth/multi-factor-auth-required') {
            ;(window as any).recaptchaVerifier = new RecaptchaVerifier(
              'recaptcha-container-id',
              {
                size: 'invisible',
                callback: () => {
                  const recaptchaRef: any = document.getElementById('recaptcha')
                  recaptchaRef.innerHTML = "<div id='recaptcha-container-id'></div>"
                },
              },
              auth
            )
            const resolver = getMultiFactorResolver(auth, error)
            setMultiFactorResolver(resolver)
            // Ask user which second factor to use.
            if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              }
              const phoneAuthProvider = new PhoneAuthProvider(auth)
              // Send SMS verification code
              return phoneAuthProvider
                .verifyPhoneNumber(
                  phoneInfoOptions,
                  (window as any).recaptchaVerifier
                )
                .then(function (verificationId) {
                  // Ask user for the SMS verification code.
                  navigate('/auth/two-factor-auth', { state: { verificationId } })
                })
                .catch((err) => {
                  setIsLoggingIn(false)
                  throw new Error(
                    messages.error[err.code] || err.message || 'Error logging in'
                  )
                })
            } else {
              // Unsupported second factor.
            }
          }
          setIsLoggingIn(false)
          throw new Error(messages.error[error.code] || error.message)
        })
  }

  /*
   * Save Provider ID
   */
  const saveProviderId = (organization: any) => {
    try {
      if (
        organization?.SAMLIdentityProvider?.providerId &&
        organization?.SAMLIdentityProvider?.providerCertificate &&
        organization?.SAMLIdentityProvider?.SSO_URL
      ) {
        localStorage.setItem(
          'providerId',
          organization?.SAMLIdentityProvider?.providerId?.toString()
        )
      } else {
        localStorage.removeItem('providerId')
      }
    } catch (e) {}
  }

  /*
   * Login with Google
   */

  const loginInWithGoogle = async () => {
    const auth = await getAuth()
    const provider = new GoogleAuthProvider()
    await signInWithPopup(auth, provider)
      .then(async (response: any) => {
        const { accessToken } = response.user
        const { user } = response

        try {
          setSession(accessToken)
          const additionalUserData: any = await fetchAdditionalUserData()

          // DON'T Allow user to sign in if email is not verified
          if (!additionalUserData?.user?.emailVerified) {
            await logout()
            // await sendVerificationEmail(accessToken)
            throw new Error(messages.error.userNotActivated)
          }

          dispatch({
            type: Types.Login,
            payload: {
              user: { ...user, ...additionalUserData.user },
              organization: additionalUserData?.organization,
            },
          })

          saveProviderId(additionalUserData?.organization)

          // Get unread messages count
          getUnreadMessagesCount()

          navigate(`/dashboard`)
        } catch (e) {
          throw new Error(e)
          // throw new Error(messages.error['auth/user-not-found'])
        }
      })
      .catch((error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          ;(window as any).recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container-id',
            { size: 'invisible' },
            auth
          )
          const resolver = getMultiFactorResolver(auth, error)
          setMultiFactorResolver(resolver)

          // Ask user which second factor to use.
          if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            const phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            }
            const phoneAuthProvider = new PhoneAuthProvider(auth)
            // Send SMS verification code
            return phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, (window as any).recaptchaVerifier)
              .then(function (verificationId) {
                // Ask user for verification code
                navigate('/auth/two-factor-auth', { state: { verificationId } })
              })
              .catch((err) => {
                const recaptchaRef: any = document.getElementById('recaptcha')
                recaptchaRef.innerHTML = "<div id='recaptcha-container-id'></div>"
                setIsLoggingIn(false)
                throw new Error(
                  messages.error[err.code] || err.message || 'Error logging in'
                )
              })
          }
        } else {
          if (
            error.code !== 'auth/popup-closed-by-user' &&
            error.code !== 'auth/cancelled-popup-request'
          ) {
            throw new Error(
              messages.error[error.code] ||
                error.message ||
                'There is no existing user record'
            )
          }
        }
      })
  }

  /*
   * Verify invitation link
   */
  const verifyInvite = async () => {
    return await get(`/auth/invite/self`, {
      headers: {
        Authorization: new URLSearchParams(location.search).get('verify') as any,
      },
    })
  }

  /*
   * Register invited user
   */
  const registerInvitedUser = async (
    password: string,
    firstName: string,
    lastName: string,
    recaptchaToken: string
  ) => {
    return await post(
      `/auth/invite`,
      { password, firstName, lastName, recaptchaToken },
      {
        headers: {
          Authorization: new URLSearchParams(location.search).get('verify') as any,
        },
      }
    )
  }

  /*
   * Register invited reseller user
   */
  const registerInvitedResellerUser = async (
    password: string,
    firstName: string,
    lastName: string,
    recaptchaToken: string
  ) => {
    return await post(
      `/auth/invite/reseller`,
      { password, firstName, lastName, recaptchaToken },
      {
        headers: {
          Authorization: new URLSearchParams(location.search).get('verify') as any,
        },
      }
    )
  }
  /*
   * Send password reset email, RESET PASSWORD
   */
  const resetPassword = async (email: string) => {
    return await post(`/auth/forgot-password`, { email })
  }

  const UseNewUserData = () => {
    const auth = getAuth()
    const [user, loading] = useAuthState(auth)
    const refetchUserData = async () => {
      const additionalData = (await fetchAdditionalUserData()) as any
      dispatch({
        type: Types.Refetch,
        payload: {
          user: { ...user, ...additionalData.user },
          organization: additionalData?.organization,
        },
      })
      saveProviderId(additionalData?.organization)
    }

    return { refetchUserData, loading }
  }

  /*
   * Fetch additional user data
   * firstName, lastName, companyName, email, id, etc.
   */
  const fetchAdditionalUserData = async () => {
    try {
      const userData = await get(`/auth/self`)
      setIsLoadingUser(false)
      return userData
    } catch (error) {
      setIsLoadingUser(false)
      throw new Error(error.message)
    }
  }

  /*
   * Get unread messages count
   */
  const getUnreadMessagesCount = async () => {
    try {
      const unreadMessagesCount: any = await get(`/messages/unread`)
      localStorage.setItem('unread', JSON.stringify(unreadMessagesCount) ?? '')
      setUnreadMessages(unreadMessagesCount ?? '')
    } catch (error) {
      throw new Error(error.message)
    }
  }

  /*
   * Login with SSO SAML
   * Check if user is already logged in and perform actions respectively
   */
  const loginWithSSO = async () => {
    const providerId = localStorage.getItem('providerId')
    if (!providerId) {
      throw new Error(messages.error.missingSAMLConfig)
    }

    const provider = new SAMLAuthProvider(providerId)
    const auth = await getAuth()

    // Gets triggered when user data / state changes
    // Will help to determine if user is logged in or not
    // Redirect user to SAML Auth Login URL if not logged in
    onAuthStateChanged(
      auth,
      (user) => {
        if (!user?.email) {
          signInWithRedirect(auth, provider)
        } else {
          navigate(`/dashboard`)
        }
      },
      (error) => {
        signInWithRedirect(auth, provider)
      }
    )
  }

  /*
   * Process the data received from SAML Identity provider after redirected from their site to here
   */
  const processSSORedirectCallback = async () => {
    const auth = await getAuth()

    // Store the user related info
    getRedirectResult(auth)
      .then(async (response: any) => {
        if (response?.user) {
          const { accessToken } = response.user
          const { user } = response

          setSession(accessToken)
          const additionalUserData: any = await fetchAdditionalUserData()

          dispatch({
            type: Types.Login,
            payload: {
              user: { ...user, ...additionalUserData?.user },
              organization: additionalUserData?.organization,
            },
          })

          saveProviderId(additionalUserData?.organization)
          navigate(`/dashboard`)
        }
      })
      .catch((error: any) => {
        // Handle error.
        console.log(error)
      })
  }

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    subdomain: string,
    companyName: string,
    recaptchaToken: string
  ) => {
    await post(`/auth/user-exists`, {
      email,
    })

    return await post(`/auth/signup`, {
      email,
      password,
      firstName,
      lastName,
      subdomain,
      companyName,
      recaptchaToken,
    })
  }

  const logout = async () => {
    setDuoVerified(false)
    const providerId = localStorage.getItem('providerId')
    setSession(null)
    setIsLoadingUser(false)
    dispatch({ type: Types.Logout })
    console.log('^^^^logging out^^^^^^^')
    // Logout from Firebase Auth
    const auth = await getAuth()
    auth.signOut().then(() => {
      if (providerId) localStorage.setItem('providerId', providerId)
      if (!location.pathname.includes(ROOTS_AUTH) && isBaseLocation) {
        navigate(`/auth/company/login`)
      } else if (!isBaseLocation) {
        navigate(`/auth/login`)
      }
    })
  }

  const verifyMobileOtp = async (code: string, id: string, notUser: any) => {
    const auth = getAuth()
    const user = auth.currentUser

    const additionalUserData: any = user ? await fetchAdditionalUserData() : {}

    const cred = PhoneAuthProvider.credential(id, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    // Complete sign-in.
    if (user) {
      if (!additionalUserData.user.MFA) {
        //Verification code send to complete enrollment of 2FA.
        return new Promise((resolve: any, reject: any) => {
          multiFactor(user)
            .enroll(multiFactorAssertion, user.displayName)
            .then(async (res) => {
              await put('/auth/self', {
                MFA: true,
              })
              setIsEnrolled(true)
              resolve('enroll')
            })
            .catch((error) => {
              if (error.code === 'auth/invalid-verification-code') {
                reject('Invalid verification code')
              } else {
                reject(error.message)
              }
            })
        })
      } else if (additionalUserData.user.MFA) {
        return new Promise((resolve: any, reject: any) => {
          multiFactorResolver
            .resolveSignIn(multiFactorAssertion)
            .then(async (response: any) => {
              const { accessToken } = response.user
              setSession(accessToken)
              await unEnrollTwoFactor()
              resolve('unenroll')
            })
            .catch((error: any) => {
              reject('Invalid verification code')
            })
        })
      }
    } else {
      //Verification code send to complete login.
      return new Promise((resolve: any, reject: any) => {
        multiFactorResolver
          .resolveSignIn(multiFactorAssertion)
          .then(async (response: any) => {
            const { accessToken } = response.user
            const { user } = response

            setSession(accessToken)
            const additionalUserData: any = await fetchAdditionalUserData()

            // DON'T Allow user to sign in if email is not verified
            if (!additionalUserData?.user?.emailVerified) {
              await logout()
              // await sendVerificationEmail(accessToken)
              throw new Error(messages.error.userNotActivated)
            }

            dispatch({
              type: Types.Login,
              payload: {
                user: { ...user, ...additionalUserData.user },
                organization: additionalUserData?.organization,
              },
            })
            setIsLoggingIn(false)
            navigate(`/dashboard`)
            resolve('login')
          })
          .catch((error: any) => {
            reject('Invalid verification code')
          })
      })
    }
  }

  const enrollTwoFactor = async (phoneNumber: string) => {
    const auth = await getAuth()
    let user: any = auth.currentUser
    let recaptchaVerifier: any
    recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container-id',
      {
        size: 'invisible',
      },
      auth
    )
    ;(window as any).recaptchaVerifier = recaptchaVerifier

    return multiFactor(user)
      .getSession()
      .then(function (multiFactorSession) {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        }

        const phoneAuthProvider = new PhoneAuthProvider(auth)
        // Send SMS verification code.
        return phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          (window as any).recaptchaVerifier
        )
      })
      .then(function (verificationId) {
        // Ask user for the verification code.
        navigate('/auth/two-factor-auth', { state: { verificationId } })
      })
      .catch((err) => {
        recaptchaVerifier.clear()
        recaptchaRef.current.innerHTML = `<div id="recaptcha-container-id" data-size="invisible"></div>`

        // ; (window as any).recaptchaVerifier = recaptchaVerifier
        if (err.code == 'auth/unverified-email') {
          sendEmailVerification(user)
          throw new Error(
            'Please verify your account through the link send to your registered email and try again'
          )
        } else if (err.code == 'auth/requires-recent-login') {
          throw new Error('reauthenticate')
        } else throw new Error(err)
      })
  }

  const unEnrollTwoFactor = async () => {
    const auth: any = getAuth()
    try {
      const multiFactorUser: any = await multiFactor(auth.currentUser)
      await multiFactorUser.unenroll(multiFactorUser.enrolledFactors[0])
      await put('/auth/self', {
        MFA: false,
      })
      return await fetchAdditionalUserData()
    } catch (e) {
      if (e.code == 'auth/requires-recent-login') {
        throw new Error('reauthenticate')
      }
    }
  }

  const signUpWithGoogle = async () => {
    const auth = await getAuth()
    const provider = new GoogleAuthProvider()
    try {
      const resp: any = await signInWithPopup(auth, provider)
      if (!resp) {
        throw new Error('Error Signing Up')
      }
      const user = resp.user
      const firebaseUserId = user.uid
      const email = user.email
      const userExists: any = await post(`/auth/user-exists`, {
        email,
      })
      if (userExists.message == 'No user found') {
        // await deleteSignUpUser()
      }
      const firstName = (user.displayName && user.displayName.split(' ')[0]) || ''
      const lastName = (user.displayName && user.displayName.split(' ')[1]) || ''
      await post(`/auth/signup/other`, {
        firstName,
        lastName,
        email,
        firebaseUserId,
      })
      const { accessToken } = user

      try {
        setSession(accessToken)
        const additionalUserData: any = await fetchAdditionalUserData()

        // DON'T Allow user to sign in if email is not verified
        if (!additionalUserData?.user?.emailVerified) {
          await logout()
          // await sendVerificationEmail(accessToken)
          throw new Error(messages.error.userNotActivated)
        }

        dispatch({
          type: Types.Login,
          payload: {
            user: { ...user, ...additionalUserData.user },
            organization: additionalUserData?.organization,
          },
        })

        saveProviderId(additionalUserData?.organization)

        // Get unread messages count
        getUnreadMessagesCount()
        return
      } catch (e) {
        throw new Error(e)
        // throw new Error(messages.error['auth/user-not-found'])
      }
    } catch (error) {
      if (
        error.code !== 'auth/popup-closed-by-user' &&
        error.code !== 'auth/cancelled-popup-request'
      ) {
        throw new Error(
          messages.error[error.code] ||
            error.message ||
            'There is no existing user record'
        )
      }
    }
  }

  const signUpWithMicrosoft = async () => {
    let user: any = {}
    const auth: any = await getAuth()
    const provider = new OAuthProvider('microsoft.com')
    try {
      const resp: any = await signInWithPopup(auth, provider)
      if (!resp) {
        throw new Error('Error Signing Up')
      }
      let user = resp.user
      const firebaseUserId = user.uid
      const email = user.email
      const userExists: any = await post(`/auth/user-exists`, {
        email,
      })
      if (userExists.message == 'No user found') {
        // await deleteSignUpUser()
      }
      const firstName = (user.displayName && user.displayName.split(' ')[0]) || ''
      const lastName = (user.displayName && user.displayName.split(' ')[1]) || ''

      await post(`/auth/signup/other`, {
        firstName,
        lastName,
        email,
        firebaseUserId,
      })
      const { accessToken } = user

      try {
        setSession(accessToken)
        const additionalUserData: any = await fetchAdditionalUserData()

        // DON'T Allow user to sign in if email is not verified
        if (!additionalUserData?.user?.emailVerified) {
          await logout()
          // await sendVerificationEmail(accessToken)
          throw new Error(messages.error.userNotActivated)
        }

        dispatch({
          type: Types.Login,
          payload: {
            user: { ...user, ...additionalUserData.user },
            organization: additionalUserData?.organization,
          },
        })

        saveProviderId(additionalUserData?.organization)

        // Get unread messages count
        getUnreadMessagesCount()
        return
      } catch (e) {
        throw new Error(e)
        // throw new Error(messages.error['auth/user-not-found'])
      }
    } catch (error) {
      if (error.code == 'auth/account-exists-with-different-credential') {
        const existingEmail = error.customData.email
        const providers = await fetchSignInMethodsForEmail(auth, existingEmail)
        if (providers.indexOf(EmailAuthProvider.PROVIDER_ID) != -1) {
          // Password account already exists with the same email.
          throw new Error(messages.error['auth/email-already-exists'])
        } else if (providers.indexOf(GoogleAuthProvider.PROVIDER_ID) != -1) {
          var googProvider = new GoogleAuthProvider()
          // Sign in user to Google with same account.
          provider.setCustomParameters({ login_hint: existingEmail })
          const result = await signInWithPopup(auth, googProvider)
          user = result.user
        }
        const resp = await linkWithPopup(user, provider)
        const email = resp.user.email
        const userExists: any = await post(`/auth/user-exists`, {
          email,
        })
        if (userExists.message == 'No user found') {
          await deleteSignUpUser()
        }
        return resp
      } else if (
        error.code !== 'auth/popup-closed-by-user' &&
        error.code !== 'auth/cancelled-popup-request'
      ) {
        throw new Error(
          messages.error[error.code] ||
            error.message ||
            'There is no existing user record'
        )
      }
    }
  }

  const deleteSignUpUser = async () => {
    const auth: any = await getAuth()
    if (auth.currentUser) return deleteUser(auth.currentUser)
  }

  const reAuthenticateUser = async (userProvidedPassword: string) => {
    const auth: any = getAuth()
    let user = auth.currentUser
    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        userProvidedPassword
      )
      const result = await reauthenticateWithCredential(auth.currentUser, credential)
      return result
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        ;(window as any).recaptchaVerifier = new RecaptchaVerifier(
          'recaptcha-container-id',
          {
            size: 'invisible',
          },
          auth
        )
        const resolver = getMultiFactorResolver(auth, error)
        setMultiFactorResolver(resolver)
        // Ask user which second factor to use.
        if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          }
          const phoneAuthProvider = new PhoneAuthProvider(auth)
          // Send SMS verification code
          return phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, (window as any).recaptchaVerifier)
            .then(function (verificationId) {
              // unEnrollTwoFactor()
              // Ask user for the SMS verification code.
              navigate('/auth/two-factor-auth', { state: { verificationId } })
            })
            .catch((err) => {
              setIsLoggingIn(false)
              throw new Error(
                messages.error[err.code] || err.message || 'Error logging in'
              )
            })
        } else {
          // Unsupported second factor.
        }
      } else {
        throw new Error(
          messages.error[error.code] ||
            error.message ||
            'Error re-authenticating user'
        )
      }
    }
  }

  const verifyOTP = async (
    otp: string,
    recaptchaToken: string,
    useBackupCode: boolean
  ) => {
    setIsProcessing(true)
    try {
      const loginRes: any = await post('/auth/otp', {
        otp,
        recaptchaToken: recaptchaToken,
        mfaHash: MFA.mfaHash,
        email: MFA.email,
        password: MFA.password,
        use_backup_code: useBackupCode,
      })
      console.log('login res====>', loginRes)

      const {
        tokens: {
          access: { token },
          refresh,
        },
        user,
      } = loginRes
      setSession(token)
      const additionalUserData: any = await fetchAdditionalUserData()
      console.log(additionalUserData)
      dispatch({
        type: Types.Login,
        payload: {
          user: {
            ...additionalUserData.user,
            accessToken: token,
            user,
            refreshToken: refresh.token,
          },
          organization: additionalUserData?.organization,
        },
      })

      setRefreshToken(refresh.token)
      saveProviderId(additionalUserData?.organization)
      // setUser(user)
      // setOrganization(additionalUserData?.organization)
      // Get unread messages count
      getUnreadMessagesCount()
      setMFADetails(null)
      navigate(`/dashboard`)
      setIsProcessing(false)
    } catch (e: any) {
      setIsProcessing(false)
      throw new Error(e.message)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        verifyBackupCode,
        duoVerified,
        verifyDuoPasscode,
        mfaStatus,
        verifySmsOTP,
        verifyDuoUser,
        changeMfa,
        setMfaStatus,
        setChangeMfa,
        failedAttempts,
        setFailedAttempts,
        generateQR,
        fetchAdditionalUserData,
        login,
        signIn,
        verifyTOTP,
        loginInWithGoogle,
        logout,
        register,
        loginWithSSO,
        processSSORedirectCallback,
        isLoggingIn,
        isLoadingUser,
        resetPassword,
        verifyInvite,
        registerInvitedUser,
        UseNewUserData,
        verifyMobileOtp,
        enrollTwoFactor,
        unEnrollTwoFactor,
        isEnrolled,
        setIsEnrolled,
        signUpWithGoogle,
        signUpWithMicrosoft,
        deleteSignUpUser,
        reAuthenticateUser,
        recaptchaRef,
        registerInvitedResellerUser,
        getUnreadMessagesCount,
        unreadMessages,
        verifyOTP,
        MFA,
        isProcessing,
        verifyOnly,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
