import React, { useState } from 'react'

import { Stack, InputAdornment, TextField, Button } from '@mui/material'
import { Box } from '@mui/system'

import Iconify from '../../../../../components/Iconify'
// import InviteCustomerUserModal from './InviteCustomerUserModal'
import { SnackbarProps } from 'src/@types/modalProps'

type Props = {
  filterName?: string
  onFilterName?: (value: string) => void
  id?: string
  refetchData?: () => Promise<void>
  snackBarProps?: SnackbarProps
}

function ResellerCustomerToolbar({
  filterName,
  onFilterName,
  id,
  refetchData,
  snackBarProps,
}: Props) {
  const [open, setOpen] = useState(false)
  //   const { setOpenToast, setToastMessage } = snackBarProps
  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        alignItems={'center'}
        sx={{ p: 1, mt: 3, mb: 1, px: 2 }}
        justifyContent="space-between"
      >
        <Box>
          <TextField
            fullWidth
            size="small"
            value={filterName}
            // onChange={(event) => onFilterName(event.target.value)}
            placeholder="Search Customer..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Stack>
    </>
  )
}

export default ResellerCustomerToolbar
