import React, { ReactNode, createContext, useEffect, useState } from 'react'

import { get } from 'src/utils/httpMethods'

export interface LogoContextType {
  logoUrl: { [key: string]: string }
  setLogoUrl: React.Dispatch<React.SetStateAction<any>>
}

export const LogosContext = createContext<LogoContextType | null>(null)

function LogoContext({ children }: { children: ReactNode }) {
  const [logoUrl, setLogoUrl] = useState({
    lightLogo: '',
    darkLogo: '',
  })

  useEffect(() => {
    get(`/resellers/info`)
      .then((res: any) => {
        console.log(res)
        const { accessTier } = res[0]
        // if (accessTier.includes('Reseller')) {
        //   setLogoUrl(res.logo)
        // }
        setLogoUrl({
          lightLogo: res[0].light_logo,
          darkLogo: res[0].dark_logo,
        })
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <LogosContext.Provider value={{ logoUrl, setLogoUrl }}>
      {children}
    </LogosContext.Provider>
  )
}

export default LogoContext
