import React, { useState } from 'react'
import { Alert, Button, Grid, Snackbar, Stack, Typography } from '@mui/material'
import Page from 'src/components/Page'
import ResellerList from 'src/sections/@dashboard/super-user/Reseller-settings/ResellerList'
import ResellerDetails from 'src/sections/@dashboard/super-user/Reseller-settings/ResellerDetails'
import InviteResellerModal from 'src/sections/@dashboard/super-user/Reseller-settings/InviteResellerModal'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useParams } from 'react-router'

export interface ContactPerson {
  name: string
  phone: string
  email: string
}
export interface Reseller {
  name: string
  id?: string
  phone: string
  address: {
    line1: string
    line2: string
    city: string
    state: string
    zip: string
  }
  subdomain: string
  logo: string
  email: string
  website: string
  taxId: string
  status: any
  notes?: any[]
}

function ResellerManagement() {
  const [open, setOpen] = useState(false)

  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const handleSnackBarClose = () => {
    setShowSnackbar(false)
  }

  return (
    <>
      <Page title="Manage Reseller">
        {/* <Typography variant="h4" sx={{ my: 2, mx: 4 }}>
        Manage Resellers
      </Typography> */}
        <Stack
          direction="row"
          justifyContent={'space-between'}
          sx={{ my: 2, mx: 4 }}
        >
          <Typography variant="h4">Manage Resellers</Typography>

          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            sx={{ mt: 1 }}
            // startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            Invite Reseller
          </Button>
        </Stack>
        <Grid container>
          <Grid item sm={1} lg={4} sx={{ position: 'relative' }}>
            <ResellerList />
          </Grid>
          <Grid item sm={1} lg={8}>
            <ResellerDetails />
          </Grid>
        </Grid>
      </Page>
      <InviteResellerModal openProps={{ open, setOpen }} />
    </>
  )
}

export default ResellerManagement
